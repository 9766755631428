import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Landing from './pages/Landing';
import Navbar from './components/layouts/Navbar'

function App() {
  const isSticky = () => {
    const elementToBeFixed = document.querySelector(".img");
    const elementToFollow = document.querySelector(".overlap-2");

    const initialOffsetTop = elementToFollow.offsetTop;
    var scrollY = window.scrollY;

    if (scrollY >= initialOffsetTop) {
      // Adjust the fixed position as needed
      elementToBeFixed.style.position = "fixed";
      elementToBeFixed.style.top = "50px";
      //elementToBeFixed.style.left = initialOffsetLeft + "px";
    } else {
      // Reset to initial position when not scrolled to the target section
      elementToBeFixed.style.position = "absolute";
      elementToBeFixed.style.top = initialOffsetTop + "px";
      //elementToBeFixed.style.left = initialOffsetLeft + "px";
    }
  }
  useEffect(() => {
    console.log("using effect");
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    }
  }, [])
  return (
    <div className="App">
      <Router>
      <div className="macbook-pro">
        <div className='div'>
        <Navbar />
        <Routes>
              <Route path='/' element={<Landing/>} />
        </Routes>
        </div>
      </div>
      </Router>   
    </div>
  );
}

export default App;
