import React from 'react'
import { Link } from 'react-router-dom'



function Navbar({ title }) {

  return (
    <div className='overlap mt-2'>
    <nav className ='navbar shadow-lg bg-black text-white'>
      <div className='container mx-auto'> 
        <div className="flex-none px-2 mx-2">
        <img className="screen-shot" src="./images/logo.png"/>
          <Link to='/' className='text-xl font-bold align-middle'>
            {title}
          </Link>
        </div>

        <div className='flex-1 px-2 mx-2'>
          <div className='flex justify-end'>
            <Link to="/" className='btn btn-ghost btn-lg rounded-btn'>
              Home
            </Link>
            <Link to="/about" className='btn btn-ghost btn-lg rounded-btn'>
              About
            </Link>
            
              <Link to="/logout" className='btn btn-ghost btn-lg rounded-btn'>
                Product
              </Link>
            
            
              <Link to="/contact" className='btn btn-ghost btn-lg rounded-btn'>
                Contact Us
              </Link>
    
            <Link to="demo.propptyfy.com" className='ml-5 btn btn-lg rounded-btn'>
              Schedule a demo
            </Link>
          </div>
        </div>
      </div>
    </nav>
    </div>
  )
}


export default Navbar