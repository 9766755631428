import React from 'react'

function Landing() {
  return (
    <>
    
      <div className="overlap-group">
        <p className="p">A Consolidated Portal for Simplified Property Management</p>
        <p className="essential-tools-for">
          Essential Tools for Property Management. Efficient &amp; Accessible Features for Tenants
        </p>
        <img className="ellipse" src="./images/Ellipse 3.png" />
        <img className="img" src="./images/Rectangle 72.png" />
        

     
        <div className="overlap-2">
          <div className="rectangle-4"></div>
          <p className="lorem-ipsum-lorem">
            <span className="span">We can help you with<br /></span>
            <span className="text-wrapper-7">
              Tenant Relations
            <br/></span>
            <span className="text-wrapper-7">
              Property Maintenance
            <br/></span>
            <span className="text-wrapper-7">
              Tenant Move-In/Move-Out Procedures
            <br/></span>
            <span className="text-wrapper-7">
              Rent Collection
            <br/></span>
          </p>
        </div>
    </div>
    <div className="overlap-3">
      <div className="rectangle-4"></div>
      <p className="lorem-ipsum-lorem-2">
        <span className="span">What we do:
          <br/></span>
          <span className="text-wrapper-7">
              Tenant Relations
            <br/></span>
            <span className="text-wrapper-7">
              Property Maintenance
            <br/></span>
            <span className="text-wrapper-7">
              Tenant Move-In/Move-Out Procedures
            <br/></span>
            <span className="text-wrapper-7">
              Rental Trend and Forecasting
            <br/></span>
            <span className="text-wrapper-7">
              Background Verification
            <br/></span>
            <span className="text-wrapper-7">
              Financial Management
            <br/></span>
            <span className="text-wrapper-7">
              Lease Maintenance
            <br/></span>
            <span className="text-wrapper-7">
              Legal Requirements
            <br/></span>
      </p>
    </div>
    <div className="overlap-5">
      <div className="group">
        <div className="overlap-group-2">
          <div className="overlap-6">
            <img className="untitled-design" src="./images/property.png" />
            <div className="text-wrapper-9">For Property Managers</div>
            <p className="text-wrapper-10">
              Essential tools for property management, emphasizing move in and move out processes
            </p>
          </div>
          <div className="overlap-7">
            <img className="untitled-design-2" src="./images/tenants.png" />
            <div className="text-wrapper-11">For Tenants</div>
            <p className="text-wrapper-12">
              Accessible features for swift communication, quick services, and rental portal
            </p>
          </div>
        </div>
      </div>
      <div className="overlap-wrapper">
        <div className="overlap-8">
          <div className="rectangle-5"></div>
          <div className="group-2">
            <img className="screen-shot-2" src="./images/logo.png" />
            <div className="text-wrapper-13">PROPPTYFY</div>
          </div>
          <p className="features-product">
            <span className="span">Features<br /><br /></span>
            <span className="text-wrapper-14"
              >Product<br /><br />Property Management<br /><br />Rent Payment<br /><br
            /></span>
          </p>
          <p className="propptify-about-us">
            <span className="span">Propptyfy<br /><br /></span>
            <span className="text-wrapper-14">About us<br /><br />Privacy &amp; Security<br /><br /><br /></span>
            <span className="text-wrapper-15"><br /><br /><br /></span>
          </p>
          <p className="company-contact-us">
            <span className="span">Company<br /><br /></span>
            <span className="text-wrapper-14">Contact Us<br /><br />FAQs<br /><br />Help Center</span>
          </p>
          <div className="text-wrapper-16">Follow Us</div>
          <img className="vector" src="./images/facebook.png" />
          <img className="vector-2" src="./images/insta.png" />
          <img className="vector-3" src="./images/youtube.png" />
          <img className="vector-4" src="./images/Linkedin.png" />
          <img className="vector-5" src="./images/twitter.png" />
          <div className="text-wrapper-17">Property Management Simplified</div>
        </div>
      </div>
    </div>
   </>   
  )
}

export default Landing